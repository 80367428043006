<template>
	<div>
		<contentHeader title="Penjualan Langsung" url="/penjualan-langsung" subTitle="Tambah Penjualan Langsung" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
			          			<h3 class="card-title col-6">Penjualan Langsung</h3>
			          		</div>
			          		<form @submit.prevent="prosesPembelian">
			          			<div class="card-body">
				          			<div class="px-lg-2">
				          				<div class="row">
				          					<div class="col">
				          						<div class="form-group">
								                  <label for="">No. Faktur <span class="text-danger">*</span></label>
								                  <input type="text" class="form-control" v-model="pembelian.nomor_faktur" required="">
								                </div>
				          					</div>
				          					<div class="col">
				          						<div class="form-group">
								                  <label for="nama_pembeli">Nama Pembeli <span class="text-danger">*</span></label>
								                  <input class="form-control" type="text" name="nama_pembeli" v-model="pembelian.nama_pembeli">
							                	</div>
				          					</div>
				          				</div>

					          			<table class="table table-bordered table-hover mt-2">
					          				<thead>
					          					<tr>
					          						<th scope="col" class="text-sm">NAMA OBAT</th>
					          						<th scope="col" class="text-sm">HARGA BELI</th>
					          						<th scope="col" class="text-sm">JUMLAH</th>
					          						<th scope="col" class="text-sm">DISKON</th>
					          						<th scope="col" class="text-sm">TOTAL (RP)</th>
					          						<th scope="col" class="text-sm"></th>
					          					</tr>
					          				</thead>
					          				<tbody>
					          					<tr v-for="row in produk_dibeli" :key="row.id">
					          						<td>
					          							<input class="form-control" type="text" name="" disabled="" v-model="row.nama_produk">
					          						</td>
					          						<td>
					          							<input class="form-control" type="number" name="" disabled="" v-model="row.harga_beli">
					          						</td>
					          						<td>
					          							<input class="form-control" type="number" name="" disabled="" v-model="row.jumlah">
					          						</td>
					          						<td>
					          							<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div class="btn btn-secondary">{{ row.label_diskon }}</div>
									                  </div>
									                  <!-- /btn-group -->
								                      <input type="number" class="form-control" step="any" v-model="row.diskon" disabled="">
									                </div>
					          						</td>
					          						<td>
					          							<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div class="btn btn-secondary">Rp. </div>
									                  </div>
									                  <!-- /btn-group -->
								                      <input type="number" class="form-control" step="any" v-model="row.total" disabled="">
									                </div>
					          						</td>
					          					</tr>

					          					<tr>
					          						<td>
					          							<multiselect style="min-width: 100px;" 
												          v-model="produk_select_value"
												          deselect-label="Can't remove this value"
												          track-by="nama"
												          label="nama"
												          placeholder="Pilih Produk"
												          tagPlaceholder=""
												          :loading="loading_produk_select"
												          :options="produk"
												          :allow-empty="false"
												          @update:model-value="pilihProduk"
												        >
												        </multiselect>
												        <input type="number" name="" class="form-control" min="0" style="visibility: hidden; height: 0px;">
					          						</td>
					          						<td>
					          							<input type="number" name="" class="form-control" v-model="pembelian_baru.harga_beli">
					          						</td>
					          						<td>
					          							<input type="number" name="" class="form-control" min="1" v-model="pembelian_baru.jumlah" @keyup="hitungTotalPembelianBaru">
					          						</td>
					          						<td>
					          							<div class="input-group mb-3">
										                  <div class="input-group-prepend">
										                    <div class="btn btn-secondary">{{ pembelian_baru.label_diskon }}</div>
										                  </div>
										                  <!-- /btn-group -->
									                      <input type="number" class="form-control" step="any" v-model="pembelian_baru.diskon" @keyup="hitungTotalPembelianBaru">
										                </div>
					          						</td>
					          						<td>
					          							<div class="input-group mb-3">
										                  <div class="input-group-prepend">
										                    <div class="btn btn-secondary">Rp. </div>
										                  </div>
										                  <!-- /btn-group -->
									                      <input type="number" class="form-control" disabled="" v-model="pembelian_baru.total">
										                </div>
					          						</td>
					          						<td><i @click="tambahPembelian" class="fas fa-plus-circle fa-2x text-secondary" style="cursor: pointer;"></i></td>
					          					</tr>
					          				</tbody>
					          			</table>

					          			<div class="row mt-2">
					          				<div class="col">
					          					<label class="text-sm">Sub Total</label>
					          					<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">Rp. </div>
							                  </div>
							                  <!-- /btn-group -->
						                      <input type="number" class="form-control" disabled="" v-model="pembelian.sub_total">
							                </div>
					          				</div>
					          				<div class="col">
					          					<label class="text-sm">Diskon</label>
					          					<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">{{ pembelian.label_diskon }}</div>
							                  </div>
							                  <!-- /btn-group -->
						                      <input type="number" class="form-control" step="any" v-model="pembelian.diskon" @keyup="hitungTotalPembelian">
							                </div>
					          				</div>
					          				<div class="col">
					          					<label class="text-sm">PPN</label>
					          					<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">{{ pembelian.label_ppn }}</div>
							                  </div>
							                  <!-- /btn-group -->
						                      <input type="number" class="form-control" step="any" v-model="pembelian.ppn" @keyup="hitungTotalPembelian">
							                </div>
					          				</div>
					          				<div class="col">
					          					<label class="text-sm">Ongkos Kirim</label>
					          					<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">Rp. </div>
							                  </div>
							                  <!-- /btn-group -->
						                      <input type="number" class="form-control" step="any" v-model="pembelian.ongkos_kirim" @keyup="hitungTotalPembelian">
							                </div>
					          				</div>
					          			</div>

					          			<div class="row">
					          				<div class="col col-lg-3">
					          					<label class="text-sm">Total</label>
					          					<div class="input-group mb-3">
							                  <div class="input-group-prepend">
							                    <div class="btn btn-secondary">Rp. </div>
							                  </div>
							                  <!-- /btn-group -->
						                      <input type="number" class="form-control" step="any" v-model="pembelian.total" disabled="">
							                </div>
					          				</div>
					          			</div>

					          			<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											  <span class="sr-only">Loading...</span>
											</div>
										</button>
				          			</div>
				          		</div>
			          		</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader2'
	import Multiselect from '@suadelabs/vue3-multiselect'

	import { onMounted, ref, computed, reactive } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'

	export default{
		components: {
			ContentHeader,
			Multiselect
		},
		setup () {
			const cabang = computed(() => store.getters['auth/cabang'])
			const pembelian = reactive({
				"nama_pembeli" : "",
				"penjual_id" : cabang.value.id,
				"nama_penjual" : cabang.value.nama,
				"nomor_faktur" : "",
				"produk" : [],
				"sub_total" : "",
				"label_diskon" : "...",
				"diskon" : "",
				"label_ppn" : "...",
				"ppn" : "",
				"ongkos_kirim" : "",
				"total" : "",
			})
			const produk_dibeli = ref([])
			const pembelian_baru = reactive({
				"produk_id" : "",
				"nama_produk" : "",
				"jenis" : "",
				"harga_beli" : "",
				"jumlah" : "",
				"label_diskon" : "...",
				"diskon" : "",
				"total" : ""
			})
			const produk = ref([])
			const loading = ref(false)
			const loading_produk_select = ref(true)
			const produk_select_value = ref('')

			const getProduk = async () => {
				let { data } = await axios.get(`api/produk/get`)

				if (data == 'kosong') {
					loading_produk_select.value = false
					produk.value = []
				} else {
					loading_produk_select.value = false
					produk.value = data
				}

			}

			// PEMBELIAN BARU
			const pilihProduk = () => {
				pembelian_baru.produk_id = produk_select_value.value.id				
				for (var i = 0; i < produk.value.length; i++) {
					if (produk.value[i].id == pembelian_baru.produk_id) {
						pembelian_baru.nama_produk = produk.value[i].nama
						pembelian_baru.jenis = produk.value[i].jenis
						pembelian_baru.harga_beli = produk.value[i].harga_beli
					}
				}
			}

			const hitungTotalPembelianBaru = () => {

				pembelian_baru.total = pembelian_baru.harga_beli * pembelian_baru.jumlah

				if (pembelian_baru.diskon != '' && pembelian_baru.diskon > 0) {
					if (pembelian_baru.diskon <= 100) {
						pembelian_baru.label_diskon = 'Persen (%)'
						let diskon = (pembelian_baru.total * pembelian_baru.diskon) / 100
						pembelian_baru.total = pembelian_baru.total - diskon
					} else {
						pembelian_baru.label_diskon = 'Rp. '
						let diskon = pembelian_baru.diskon
						pembelian_baru.total = pembelian_baru.total - diskon
					}
				} else {
						pembelian_baru.label_diskon = '...'
				}
			}

			const tambahPembelian = () => {
				let label = '...'
				produk_select_value.value = ''

				if (pembelian_baru.diskon != '') {
					if (pembelian_baru.diskon <= 100) {
						label = 'Persen (%)'
					} else {
						label = 'Rp. '
					}
				} 

				produk_dibeli.value.push({
					"produk_id" : pembelian_baru.produk_id,
					"nama_produk" : pembelian_baru.nama_produk,
					"jenis" : pembelian_baru.jenis,
					"harga_beli" : pembelian_baru.harga_beli,
					"jumlah" : pembelian_baru.jumlah,
					"label_diskon" : label,
					"diskon" : pembelian_baru.diskon,
					"total" : pembelian_baru.total
				})

				pembelian_baru.produk_id = ''
				pembelian_baru.nama_produk = ''
				pembelian_baru.jenis = ''
				pembelian_baru.harga_beli = ''
				pembelian_baru.jumlah = ''
				pembelian_baru.label_diskon = '...'
				pembelian_baru.diskon = ''
				pembelian_baru.total = ''

				pembelian.sub_total = 0
				pembelian.total = 0
				for (var i = 0; i < produk_dibeli.value.length; i++) {
					pembelian.sub_total = pembelian.sub_total + produk_dibeli.value[i].total
					pembelian.total = pembelian.sub_total
				}
			}
			// END PEMBELIAN BARU

			const hitungTotalPembelian = () => {
				pembelian.total = pembelian.sub_total

				if (pembelian.diskon != '') {
					if (pembelian.diskon <= 100) {
						pembelian.label_diskon = 'Persen (%)'
						let diskon = (pembelian.sub_total * pembelian.diskon) / 100
						pembelian.total = pembelian.total - diskon
					} else {
						pembelian.label_diskon = 'Rp. '
						let diskon = pembelian.diskon
						pembelian.total = pembelian.total - diskon
					}
				} else {
					pembelian.label_diskon = '...'
				}

				if (pembelian.ppn != '') {
					if (pembelian.ppn <= 100) {
						pembelian.label_ppn = 'Persen (%)'
						let ppn = (pembelian.sub_total * pembelian.ppn) / 100
						pembelian.total = pembelian.total + ppn
					} else {
						pembelian.label_ppn = 'Rp. '
						let ppn = pembelian.ppn
						pembelian.total = pembelian.total + ppn
					}
				} else {
					pembelian.label_ppn = '...'
				}

				if (pembelian.ongkos_kirim != '') {
					pembelian.total = pembelian.total + parseInt(pembelian.ongkos_kirim)
				}
			}

			const prosesPembelian = () => {
				loading.value = true
				pembelian.produk = produk_dibeli.value
				
				axios.post('api/pembelian/penjualanLangsung', pembelian)
				.then((response) => {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil Menambahkan Data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})
					loading.value = false
					router.push('/penjualan-langsung')
					console.log(response)
				})
				.catch((error) => {
					Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal menambahkan data',
					  icon: 'error',
					  showConfirmButton: false,
					  timer: 1500
					})	
					loading.value = false
					console.log(error)
				})
			}


			onMounted(() => {
				getProduk()
			})

			return { produk, pembelian, pembelian_baru, tambahPembelian, pilihProduk, hitungTotalPembelianBaru, produk_dibeli, hitungTotalPembelian, prosesPembelian, loading, produk_select_value, loading_produk_select}
		}

	}
</script>